import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const rulesApi = createApi({
  reducerPath: `rulesApi`,
  baseQuery: fetchBaseQuery({ baseUrl: `/api/geebeeRoute?path=/rule` }),
  endpoints: () => ({}),
});

export const rulesBaseApiWithTags = rulesApi.enhanceEndpoints({
  addTagTypes: [`RulesList`],
});
