import React, { useEffect, useRef } from 'react';
import { Field, Form } from 'react-final-form';
import {
  Button,
  FFStudentContactNumberField,
  isFalsy,
} from '@leapfinance/frontend-commons';
import CreateOrSearchStudentField from '@leapfinance/frontend-commons/components/geebee/CreateOrSearchStudent';
import GeebeeTheme from '@leapfinance/frontend-commons/components/geebee/theme';
import { type OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import { CloseRounded } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { type FormApi } from 'final-form';
import { debounce } from 'lodash';

import { CFEventsType, CfEventUtil } from '@/components/CF/CFEventsUtil';
import useGetStudentOptions from '@/components/CF/Dashboard/hooks/useGetStudentOptions';
import {
  useBulkAddToShortlistMutation,
  useGetCFCourseDetailsByIdQuery,
} from '@/components/CF/Dashboard/services/geebeeCf';
import useModal from '@/components/modals/useModal';
import SelectedCourseItem from '@/pages/course-details/SelectedCourseItem';
import { useLazyGetShortlistDataQuery } from '@/services/courseFinder';
import {
  useCreateStudentMutation,
  useLazyCheckDuplicateQuery,
  useSearchStudentMutation,
} from '@/services/student';
import { SegmentEventSource } from '@/types/segmentEvents';
import { CreateStudentType } from '@/validations/createStudent.schema';

export type AddCourseToShortlistProps = {
  student?: {
    label: string;
    value: any;
  };
  courseDetails: {
    courseName?: string;
    universityName?: string;
    cityName?: string;
    countryName?: string;
    courseId: number;
  };
};

function AddCourseToShortlist({
  student,
  courseDetails,
}: AddCourseToShortlistProps) {
  const [triggerGetShortlist, { data, isLoading: studentShortlistLoading }] =
    useLazyGetShortlistDataQuery();
  const [debouncedGetStudents] = useGetStudentOptions();
  // to make data consistent with CF
  const { data: courseData } = useGetCFCourseDetailsByIdQuery(
    {
      id: courseDetails?.courseId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (!isFalsy(student)) {
      triggerGetShortlist({
        studentId: student?.value ?? -1,
      });
    }
  }, [student]);

  const getSelectedCourseDetails = () => {
    const courses =
      (data?.data?.shortlist ?? []).map(
        (shortlistItem) => shortlistItem.courses,
      ) ?? [];
    const finalCoursesList = courses?.flat() ?? [];

    return (
      finalCoursesList.find(
        (item) => item?.courseId === courseDetails?.courseId,
      ) ?? {}
    );
  };

  const getCourseDetailsWithShortlistedFlag = (values: any) => {
    const courseWithShortlistedFlag = getSelectedCourseDetails();

    if (
      !isFalsy(courseWithShortlistedFlag) &&
      values?.createStudentMode !== `yes` &&
      !isFalsy(values?.student)
    ) {
      return {
        ...courseWithShortlistedFlag,
        shortlisted: true,
      };
    }

    return {
      ...courseDetails,
      shortlisted: false,
    };
  };

  const { hideModal } = useModal();

  const formRef = useRef<FormApi<any, any> | null>(null);
  const [triggerDuplicateStudentCheck, { data: existingStudentData }] =
    useLazyCheckDuplicateQuery();
  const [createStudentTrigger] = useCreateStudentMutation();
  const [
    addToShortlistTrigger,
    { isLoading: isLoadingResults, data: shortlistAddedData },
  ] = useBulkAddToShortlistMutation();
  const [triggerSearchStudent] = useSearchStudentMutation();

  // const getStudentSuggestions = (
  //   searchQuery: string,
  //   callback: (results?: readonly OPTION_TYPE[] | undefined) => void,
  // ) => {
  //   if (searchQuery?.length > 0) {
  //     try {
  //       triggerSearchStudent({
  //         searchString: searchQuery,
  //         pageNumber: 0,
  //         pageSize: 10,
  //       })
  //         .unwrap()
  //         .then((data: any) => {
  //           const newStudentlist = [] as any;
  //           const list = data?.data?.students as any;
  //           for (let i = 0; i < list.length; i++) {
  //             newStudentlist.push({
  //               value: list[i]?.id,
  //               label: list[i]?.result,
  //             });
  //           }
  //           callback(newStudentlist);
  //         });
  //     } catch (err: any) {
  //       console.log(err.message);
  //     }
  //   } else {
  //     callback([]);
  //   }
  // };
  // const debouncedGetStudents = debounce(getStudentSuggestions, 500);

  const handlePhoneNumberValidation = (value: string) => {
    const values = formRef?.current?.getState()?.values;
    if (
      isNaN(Number(value)) ||
      isNaN(Number(values?.countryCode?.value)) ||
      value?.length !== 10
    ) {
      return;
    }
    triggerDuplicateStudentCheck({
      mobileNumber: `${values?.countryCode?.value}${value}`,
      cspId: `0`,
    });
  };
  const handleCreateNewStudent = async (values: any) => {
    const payload: CreateStudentType = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      contactNumber: values?.phoneNumber,
      countryCode: values?.countryCode?.value,
    };
    const res = await createStudentTrigger(payload).unwrap();
    if (res?.success && res?.data?.id) {
      values.studentId = res?.data?.id;
      return {
        label: `${values?.firstName} ${values?.lastName}`,
        value: res?.data?.id,
      };
    }
    return null;
  };
  const addToShortlist = async (values: any) => {
    CfEventUtil.triggerCfEvent(CFEventsType.CD_ADD_SHORTLIST_MODAL, {
      restInfo: {
        courseId: courseDetails?.courseId,
        courseName: courseDetails?.courseName as string,
        source: SegmentEventSource.CF,
      },
    });
    let shortlistReferenceStudent;
    if (values?.firstName) {
      shortlistReferenceStudent = await handleCreateNewStudent(values);
      formRef.current?.change(`student`, shortlistReferenceStudent);
    }

    addToShortlistTrigger({
      studentId:
        shortlistReferenceStudent?.value ?? (values?.student?.value as number),
      courses: [courseData?.data],
    }).unwrap();
  };

  const handleStudentChange = (_: any, student: any) => {
    if (student?.__isNew__) {
      const studentLabel = student?.label?.trim();
      formRef?.current?.batch(() => {
        formRef?.current?.change(`firstName`, studentLabel?.split(` `)[0]);
        formRef?.current?.change(`lastName`, studentLabel?.split(` `)[1]);
        formRef?.current?.change(`countryCode`, { label: `+91`, value: `+91` });
        formRef?.current?.change(`createStudentMode`, `yes`);
      });
      return;
    }

    if (!isFalsy(student?.value)) {
      triggerGetShortlist({
        studentId: student.value,
      });
    }
  };

  const handleRedirectToShortlist = (values: any) => {
    window.open(
      `/student/` + values?.student?.value + `?active=SHORTLIST`,
      `_blank`,
    );
  };

  const getTooltipMessage = (values: any, errors: any) => {
    if (values?.createStudentMode === `yes`) {
      if (!isFalsy(values?.phoneNumber)) {
        if (!isFalsy(errors?.phoneNumber)) {
          return `Please enter a valid phone number`;
        } else if (
          !isFalsy(existingStudentData?.data?.error) ||
          !isFalsy(existingStudentData?.data?.warning)
        ) {
          return `Student already exists`;
        }
      } else {
        return `Add a new student`;
      }
    } else {
      return `Please select a student or Add a new student`;
    }
  };

  if (typeof window === `undefined`) return null;

  return (
    <ThemeProvider theme={GeebeeTheme}>
      <div className="flex items-center justify-center w-full h-full">
        <div className="bg-white text-black  z-10 relative max-h-[500px] w-[500px]">
          <div
            className="sticky top-0 z-[999] bg-white border-b flex flex-col justify-center w-full"
            id="selected-course-select-student"
          >
            <div className="w-full flex flex-row justify-between items-center px-4 py-1">
              <Typography variant="subtitle1">
                Add this course to shortlist
              </Typography>
              <IconButton
                onClick={() => {
                  hideModal();
                }}
              >
                <CloseRounded />
              </IconButton>
            </div>
          </div>
          <Divider />
          <Form
            onSubmit={addToShortlist}
            initialValues={{ student: student, createStudentMode: `no` }}
            render={({ form, handleSubmit, values, errors }) => {
              formRef.current = form;
              const hasPhoneNumberValidationError = !isFalsy(
                errors?.phoneNumber,
              );
              const studentAlreadyExistWithGivenPhoneNumber = !isFalsy(
                existingStudentData?.data?.studentId,
              );
              const addToShortlistButtonDisabled =
                isLoadingResults ||
                (values?.createStudentMode === `yes`
                  ? hasPhoneNumberValidationError ||
                    studentAlreadyExistWithGivenPhoneNumber
                  : !values?.student);

              return (
                <>
                  {shortlistAddedData?.success ? (
                    <div className="flex flex-col justify-center items-center py-6 gap-y-6 px-6">
                      <img
                        height={100}
                        width={100}
                        src="https://ik.imagekit.io/onsnhxjshmp/geebee-emails/VAS/Success%20(2)_AdfdATl7j.gif?updatedAt=1703778274029"
                        alt="success"
                      />
                      <Typography variant="subtitle2" className="text-grey-900">
                        Course Successfully Added to{` `}
                        {values?.student?.label?.split(`-`)?.[0] ??
                          values?.student?.label}
                        `s
                        {` `}
                        Shortlist.
                      </Typography>
                    </div>
                  ) : (
                    <>
                      <div>
                        <div className="px-4 pt-6">
                          <Field
                            name="createStudentMode"
                            component="input"
                            type="hidden"
                          />
                          <CreateOrSearchStudentField
                            studentFieldProps={{
                              name: `student`,
                            }}
                            firstNameFieldProps={{
                              name: `firstName`,
                              validate: (value) => {
                                if (!value) {
                                  return `Please enter first name`;
                                }
                              },
                            }}
                            lastNameFieldProps={{
                              name: `lastName`,
                              validate: (value) => {
                                if (!value) {
                                  return `Please enter last name`;
                                }
                              },
                            }}
                            onCreateStudentClick={(value) => {
                              form.batch(() => {
                                form.change(`createStudentMode`, value);
                                form.change(`countryCode`, {
                                  label: `+91`,
                                  value: `+91`,
                                });
                              });
                            }}
                            onDeleteStudent={() => {
                              form.batch(() => {
                                form.change(`firstName`, undefined);
                                form.change(`lastName`, undefined);
                                form.change(`student`, undefined);
                                form.change(`countryCode`, undefined);
                                form.change(`phoneNumber`, undefined);
                              });
                            }}
                            studentcomponentProps={{
                              options: [],
                              fetchOptionsHandler: debouncedGetStudents,
                              // async: {
                              //   // @ts-ignore
                              //   loadOptions:
                              //     debouncedGetStudents as unknown as any,
                              // },

                              disableClearable: false,
                              creatable: true,
                              creatableText: (inputValue) =>
                                `Add Student - ${inputValue}`,
                              onChange: handleStudentChange,
                              textInputProps: {
                                placeholder: `Please enter a student name`,
                              },
                            }}
                            studentCreateModeDefaultValue={
                              values?.createStudentMode === `yes`
                            }
                          />
                          {values?.createStudentMode === `yes` ? (
                            <FFStudentContactNumberField
                              countryCodeFieldProps={{
                                name: `countryCode`,
                              }}
                              phoneNumberFieldProps={{
                                name: `phoneNumber`,
                                validate: (value) => {
                                  if (!value || value?.length !== 10) {
                                    return `Please enter a valid phone number`;
                                  }
                                },
                              }}
                              formValues={values}
                              existingStudentData={{
                                data: (existingStudentData?.data as any) ?? {},
                              }}
                              handlePhoneNumberValidation={(value) => {
                                handlePhoneNumberValidation(value);
                              }}
                              onRedirectToExistingStudent={() => {
                                window.open(
                                  `/profile/${existingStudentData?.data?.studentId}?key=PROFILE`,
                                  `_blank`,
                                );
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-col px-4 pt-6 w-full">
                        {isLoadingResults || studentShortlistLoading ? (
                          <div
                            key={`selected-course`}
                            className="grid grid-cols-[10%_80%_10%] mb-4 items-start"
                          >
                            <Skeleton
                              variant="rectangular"
                              className="h-4 w-4 m-[9px]"
                            />
                            <div>
                              <Skeleton variant="text" />
                              <Skeleton variant="text" className="w-[40%]" />
                              <div className="flex justify-start items-center gap-2">
                                <Skeleton variant="text" className="w-[50%]" />
                                <Skeleton variant="text" className="w-[50%]" />
                              </div>
                            </div>
                            <Skeleton
                              variant="rectangular"
                              className="h-7 w-5 m-2"
                            />
                          </div>
                        ) : (
                          <SelectedCourseItem
                            course={getCourseDetailsWithShortlistedFlag(values)}
                          />
                        )}
                      </div>
                    </>
                  )}
                  <div className="[&>button]:shadow-lg flex items-center justify-center gap-4 px-4 py-6 sticky bottom-0 bg-white">
                    {shortlistAddedData?.success ||
                    getCourseDetailsWithShortlistedFlag(values)?.shortlisted ? (
                      <>
                        <Button
                          loading={isLoadingResults}
                          disableElevation={false}
                          className="w-full drop-shadow-lg"
                          onClick={() => {
                            CfEventUtil.triggerCfEvent(
                              CFEventsType.CD_VIEW_SHORTLIST_IN_MODAL,
                              {
                                restInfo: {
                                  courseId: courseDetails?.courseId,
                                  courseName:
                                    courseDetails?.courseName as string,
                                  source: SegmentEventSource.CF,
                                },
                              },
                            );
                            handleRedirectToShortlist(values);
                          }}
                        >
                          View Shortlisted Course
                        </Button>
                      </>
                    ) : (
                      <>
                        {addToShortlistButtonDisabled ? (
                          <Tooltip
                            title={`${getTooltipMessage(values, errors)}`}
                            arrow
                            placement="top"
                          >
                            <div className="w-full drop-shadow-lg">
                              <Button
                                loading={isLoadingResults}
                                disableElevation={false}
                                disabled={addToShortlistButtonDisabled}
                                className="read-only-cta w-full drop-shadow-lg"
                                onClick={handleSubmit}
                              >
                                Add to Shortlist
                              </Button>
                            </div>
                          </Tooltip>
                        ) : (
                          <Button
                            loading={isLoadingResults}
                            disableElevation={false}
                            disabled={addToShortlistButtonDisabled}
                            className="read-only-cta w-full drop-shadow-lg"
                            onClick={handleSubmit}
                          >
                            Add to Shortlist
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </>
              );
            }}
          />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default AddCourseToShortlist;
