import { NoResultsProps } from '@leapfinance/frontend-commons/components/NoResults';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';

import { MoreFiltersModalProps } from '@/components/AdminDashboard/Tasks/Filters/MoreFiltersModal';
import { NextActionModalProps } from '@/components/AdminDashboard/Tasks/TaskDetails/NextActionModal';
import { SuccessfulUpdateModalProps } from '@/components/AdminDashboard/Tasks/TaskDetails/SuccessfulUpdateModal';
import { IBulkUpdateModalProps } from '@/components/Commission/BulkUpdateModal';
import { ICommissionVersionHistoryModalProps } from '@/components/Commission/CommissionVersionHistoryModal';
import { IStudentCommissionMoreFilter } from '@/components/Commission/StudentListPage/StudentListFilters/StudentCommissionMoreFilter';
import { CspMoreFiltersModalProps } from '@/components/CspManagement/CspMoreFilters';
import { DeferralHistoryModalProps } from '@/components/Deferral/DeferralHistoryModal';
import { IIeltsVerificationModalProps } from '@/components/IeltsVerification/IeltsVerificationModal';
import { IAddToBookmark } from '@/components/modals/AddToBookmark';
import { CommissionDuplicateStudentsModalProps } from '@/components/modals/CommissionDuplicateStudentsModal';
import { CounsellorInitiateJerryCallProps } from '@/components/modals/CounsellorPerformance/CounsellorInitiateJerryCall';
import { CounsellorPerformanceCspDetailsModalProps } from '@/components/modals/CounsellorPerformance/CounsellorPerformanceCspDetailsModal';
import { ICreateBookmarkListModalProps } from '@/components/modals/CreateBookmarkListModal';
import { ICreateCMSInstituteModalProps } from '@/components/modals/CreateCMSInstituteModal';
import { DeleteVisaRequestWarningType } from '@/components/modals/DeleteVisaRequestWarningModal';
import { IDownloadApplicationCsv } from '@/components/modals/DownloadApplicationCsv';
import { VASDrawerProps } from '@/components/modals/Drawers/VASDrawer';
import { IMarkProgramClosedModal } from '@/components/modals/MarkProgramClosedModal';
import { RaiseShortlistRequestProps } from '@/components/modals/RaiseShortlistRequest';
import { RecommendedCoursesModalProps } from '@/components/modals/RecommendedCoursesModal';
import { ReportCourseGlobalModalProps } from '@/components/modals/ReportCourseGlobalModal';
import { IShortlistConditionalModal } from '@/components/modals/ShortlistConditionalModal';
import { ShortlistRemarksModalProps } from '@/components/modals/ShortlistRemarksModal';
import { SimilarVisaRequestWarningProps } from '@/components/modals/SimilarVisaRequestWarning';
import { StudentDownloadFormProps } from '@/components/modals/StudentDownloadForm';
import { ITaskEmailEditorModalProps } from '@/components/modals/TaskEmailEditorModal/TaskEmailEditorModal';
import { UpdateInfoModalProps } from '@/components/modals/UpdateInfoModal';
import { IUploadAndMapDocumentsModalProps } from '@/components/modals/UploadAndMapDocumentsModal';
import { SOPDraftPlagiarismModalProps } from '@/components/SOP/SOPPlagiarism/SOPDraftPlagiarismModal';
import { IEmailConfirmationModal } from '@/components/Student/Shortlist/ShortlistComponents/ShortlistFormComponents/EmailConfirmationModal';
import { IAddSugAgentFormProps } from '@/components/SubAgent/AddSugAgentForm';
import { CSVExportModalProps } from '@/components/UnisetuDashboard/ExportModal';
import { IUnisetuDashboardMoreFilter } from '@/components/UnisetuDashboard/Filters/UnisetuDashboardMoreFilters';
import { AdditionalPartnerDetailsModalProps } from '@/components/UserManagement/AdditionalPartnerDetailsModal';
import { VASMoreFiltersProps } from '@/components/vas/VASMoreFilters';
import { AddCourseToShortlistProps } from '@/pages/course-details/AddCourseToShortlist';
import { AddCourseI } from 'components/modals/AddCourse';
import { MODAL_TYPES } from 'components/modals/GlobalModal';

import { IAddApplication } from '../../../components/modals/AddApplication';
import { IAddStudentModalProps } from '../../../components/modals/AddStudentModal';
import { ApplicationDocInfoModalProps } from '../../../components/modals/ApplicationDocInfoModal';
import { DocumentRejectModalProps } from '../../../components/modals/DocumentRejectModal';
import { PreVisaModalProps } from '../../../components/modals/PreVisaInfoModal';
import { StudentDocInfoModalProps } from '../../../components/modals/StudentDocInfoModal';

type AddCourseModalType = {
  modalType: MODAL_TYPES.ADD_COURSE | null;
  modalProps: AddCourseI | null;
};
type AddStudentModalType = {
  modalType: MODAL_TYPES.ADD_STUDENT | null;
  modalProps: IAddStudentModalProps | null;
};
type AddApplicationModalType = {
  modalType: MODAL_TYPES.ADD_APPLICATION | null;
  modalProps: IAddApplication | null;
};
type StudentDocInfoModalType = {
  modalType: MODAL_TYPES.STUDENT_DOCUMENT_INFO | null;
  modalProps: StudentDocInfoModalProps | null;
};
type MandatoryDocumentsModalType = {
  modalType: MODAL_TYPES.MANDATORY_DOC | null;
  modalProps: ApplicationDocInfoModalProps | null;
};

type DownloadApplicationCsvType = {
  modalType: MODAL_TYPES.DOWNLOAD_APPLICATION_CSV | null;
  modalProps: IDownloadApplicationCsv | null;
};

type PreVisaInfoModal = {
  modalType: MODAL_TYPES.PRE_VISA_INFO | null;
  modalProps?: PreVisaModalProps | null;
};

type UploadAndMapDocumentsModalType = {
  modalType: MODAL_TYPES.UPLOAD_AND_MAP_DOCS | null;
  modalProps?: IUploadAndMapDocumentsModalProps | null;
};

type DocumentRejectModal = {
  modalType: MODAL_TYPES.DOCUMENT_REJECT_MODAL | null;
  modalProps?: DocumentRejectModalProps | null;
};

type ShortlistConditionalModal = {
  modalType: MODAL_TYPES.SHORTLIST_CONDITIONAL_MODAL;
  modalProps: IShortlistConditionalModal | null;
};

type NoResultsModal = {
  modalType: MODAL_TYPES.NO_RESULTS | null;
  modalProps: NoResultsProps | null;
};

type SimilarVisaRequestWarningModal = {
  modalType: MODAL_TYPES.SIMILAR_VISA_REQUEST_WARNING_MODAL | null;
  modalProps: SimilarVisaRequestWarningProps | null;
};

type DeleteVisaRequestWarningModal = {
  modalType: MODAL_TYPES.DELETE_VISA_REQUEST_WARNING_MODAL | null;
  modalProps: DeleteVisaRequestWarningType | null;
};

type AddToBookmarksModalType = {
  modalType: MODAL_TYPES.ADD_BOOKMARK | null;
  modalProps: IAddToBookmark | null;
};

type CreateBookmarkListModal = {
  modalType: MODAL_TYPES.CREATE_BOOKMARK_LIST_MODAL | null;
  modalProps: ICreateBookmarkListModalProps | null;
};

type CommissionDuplicateStudentsModal = {
  modalType: MODAL_TYPES.COMMISSION_DUPLICATE_STUDENTS_MODAL | null;
  modalProps: CommissionDuplicateStudentsModalProps | null;
};

type CommissionVersionHistoryModal = {
  modalType: MODAL_TYPES.COMMISSION_VERSION_HISTORY | null;
  modalProps: ICommissionVersionHistoryModalProps | null;
};

type StundetCommissionMoreFilter = {
  modalType: MODAL_TYPES.STUDENT_COMMISSION_MORE_FILTER;
  modalProps: IStudentCommissionMoreFilter | null;
};

type CommissionBulkUpdateModal = {
  modalType: MODAL_TYPES.COMMISSION_BULK_UPDATE_MODAL;
  modalProps: IBulkUpdateModalProps | null;
};
type UpdateInfoModal = {
  modalType: MODAL_TYPES.UPDATE_INFO_MODAL | null;
  modalProps: UpdateInfoModalProps | null;
};

type ShortlistRemarksModal = {
  modalType: MODAL_TYPES.SHORTLIST_REMARKS_MODAL | null;
  modalProps: ShortlistRemarksModalProps | null;
};
type CreateCMSInstituteModalType = {
  modalType: MODAL_TYPES.CREATE_CMS_INSTITUTE_MODAL | null;
  modalProps: ICreateCMSInstituteModalProps | null;
};

type MarkProgramClosedModal = {
  modalType: MODAL_TYPES.MARK_PROGRAM_CLOSED_MODAL | null;
  modalProps: IMarkProgramClosedModal | null;
};

type GTEModalType = {
  modalType: MODAL_TYPES.GTE_PROCESS | null;
  modalProps: null;
};

type IeltsVerificationModal = {
  modalType: MODAL_TYPES.IELTS_VERIFICATION_MODAL;
  modalProps: IIeltsVerificationModalProps;
};

type UnisetuDashboardMoreFiltersType = {
  modalType: MODAL_TYPES.UNISETU_DASHBOARD_MORE_FILTER;
  modalProps: IUnisetuDashboardMoreFilter;
};

type CSVExportModalType = {
  modalType: MODAL_TYPES.CSV_EXPORT_MODAL;
  modalProps: CSVExportModalProps;
};

type VASDrawer = {
  modalType: MODAL_TYPES.VAS_DRAWER;
  modalProps: VASDrawerProps;
  variant: 'drawer';
};
type ReportCourseGlobalModalType = {
  modalType: MODAL_TYPES.REPORT_COURSE_GLOBAL_MODAL | null;
  modalProps: ReportCourseGlobalModalProps;
};

type TaskEmailEditorModalType = {
  modalType: MODAL_TYPES.TASK_EMAIL_EDITOR_MODAL | null;
  modalProps: ITaskEmailEditorModalProps;
};
type AddSubAgentModalType = {
  modalType: MODAL_TYPES.ADD_SUB_AGENT_FORM | null;
  modalProps: IAddSugAgentFormProps;
};
type DeferralHistoryModalType = {
  modalType: MODAL_TYPES.DEFFERAL_HISTORY_MODAL | null;
  modalProps: DeferralHistoryModalProps;
};

type RecommendedCoursesModalType = {
  modalType: MODAL_TYPES.RECOMMENDED_COURSES_MODAL | null;
  modalProps: RecommendedCoursesModalProps;
};

type VASMoreFiltersModalProps = {
  modalType: MODAL_TYPES.VAS_MORE_FILTERS | null;
  modalProps: VASMoreFiltersProps;
};

type MoreFiltersModalType = {
  modalType: MODAL_TYPES.EMAIL_AUTOMATION_MORE_FILTERS_MODAL;
  modalProps: MoreFiltersModalProps;
};

type CounsellorPerformanceCspDetailsModalPropsType = {
  modalType: MODAL_TYPES.COUNSELLOR_CSP_PERFORMANCE_DETAILS_MODAL;
  modalProps: CounsellorPerformanceCspDetailsModalProps;
};

type CounsellorInitiateCSPCallPropsType = {
  modalType: MODAL_TYPES.COUNSELLOR_CSP_INITIATE_CALL;
  modalProps: CounsellorInitiateJerryCallProps;
};
type NextActionModalType = {
  modalType: MODAL_TYPES.NEXT_ACTION_MODAL;
  modalProps: NextActionModalProps;
};

type SuccessfulUpdateModalType = {
  modalType: MODAL_TYPES.SUCCESSFUL_UPDATE_MODAL;
  modalProps: SuccessfulUpdateModalProps;
};

type RaiseShortlistRequestModalType = {
  modalType: MODAL_TYPES.RAISE_SHORTLIST_REQUEST_MODAL;
  modalProps: RaiseShortlistRequestProps;
};

type EmailConfirmationModalType = {
  modalType: MODAL_TYPES.EMAIL_CONFIRMATION;
  modalProps: IEmailConfirmationModal;
};
type StudentDownloadFormModalType = {
  modalType: MODAL_TYPES.STUDENT_DOWNLOAD_FORM_MODAL;
  modalProps: null;
};

type AddCourseToShortlistModalType = {
  modalType: MODAL_TYPES.ADD_COURSE_TO_SHORTLIST | null;
  modalProps: AddCourseToShortlistProps;
};

type CspMoreFiltersModalType = {
  modalType: MODAL_TYPES.CSP_MORE_FILTERS;
  modalProps: CspMoreFiltersModalProps;
};

type AdditionalDetailsModalType = {
  modalType: MODAL_TYPES.ADDITIONAL_USER_DETAILS;
  modalProps: AdditionalPartnerDetailsModalProps;
};

type SOPDraftPlagiarismModalType = {
  modalType: MODAL_TYPES.SOP_DRAFT_PLAGIARISM_CHECK;
  modalProps: SOPDraftPlagiarismModalProps;
};

export type ModalDataType =
  | AddCourseModalType
  | AddStudentModalType
  | AddApplicationModalType
  | StudentDocInfoModalType
  | MandatoryDocumentsModalType
  | DownloadApplicationCsvType
  | PreVisaInfoModal
  | UploadAndMapDocumentsModalType
  | ShortlistConditionalModal
  | NoResultsModal
  | DocumentRejectModal
  | SimilarVisaRequestWarningModal
  | DeleteVisaRequestWarningModal
  | AddToBookmarksModalType
  | CreateBookmarkListModal
  | CommissionVersionHistoryModal
  | StundetCommissionMoreFilter
  | CommissionDuplicateStudentsModal
  | CommissionBulkUpdateModal
  | UpdateInfoModal
  | ShortlistRemarksModal
  | MarkProgramClosedModal
  | CreateCMSInstituteModalType
  | GTEModalType
  | IeltsVerificationModal
  | VASDrawer
  | ReportCourseGlobalModalType
  | UnisetuDashboardMoreFiltersType
  | CSVExportModalType
  | TaskEmailEditorModalType
  | AddSubAgentModalType
  | DeferralHistoryModalType
  | RecommendedCoursesModalType
  | NextActionModalType
  | SuccessfulUpdateModalType
  | VASMoreFiltersModalProps
  | MoreFiltersModalType
  | CounsellorPerformanceCspDetailsModalPropsType
  | CounsellorInitiateCSPCallPropsType
  | AddCourseToShortlistModalType
  | RaiseShortlistRequestModalType
  | EmailConfirmationModalType
  | StudentDownloadFormModalType
  | CspMoreFiltersModalType
  | AdditionalDetailsModalType
  | SOPDraftPlagiarismModalType;

export type ModalType = ModalDataType[];

const initialState: ModalType = [];

export const modalsSlice = createSlice({
  name: `modals`,
  initialState,
  reducers: {
    updateModal: (state, action: PayloadAction<ModalDataType>) => {
      const newModal = [...state];
      const findModalIndex = state.findIndex(
        (eachModal) => eachModal.modalType === action.payload.modalType,
      );
      if (findModalIndex >= 0) {
        newModal.splice(findModalIndex, 1, action.payload);
      } else {
        newModal.push(action.payload);
      }
      return newModal;
    },
    removeModal: (state, action: PayloadAction<MODAL_TYPES | undefined>) => {
      if (!action.payload || typeof action.payload !== `string`) {
        return [];
      }
      const newModal = state.filter(
        (eachModal) => eachModal.modalType !== action.payload,
      );
      return newModal;
    },
  },
});
export const selectModal = (state: RootState) => {
  return state.modals || [];
};
export const { updateModal, removeModal } = modalsSlice.actions;

export default modalsSlice.reducer;
