import { geebeeRootBaseApiWithTags } from 'services/base/geebeeApi';
import { definitions } from 'types/schema';
import {
  BaseResponse,
  CheckDuplicateApiResponse,
  GetAllCSPSResponse,
  GetAllCSPStudentRequest,
  GetAllCSPStudentResponse,
  GetCspStudentDocumentsresponse,
  GetStudentShortlistsResponse,
  StudentDetailsViewRequest,
  UpdateCSPStudentInfoRequest,
  UpdateCSPStudentInfoResponse,
} from 'types/service';
import {
  UpdateShortListRequestRequest,
  UpdateShortListRequestResponse,
} from 'types/service';

import {
  CourseEligibilityCheckBulkRequestDto,
  CourseEligibilityCheckBulkResponseDto,
  CourseEligibilityCheckRequestDto,
  CourseEligibilityCheckResponseDto,
  CreateShortlistRequestResponseType,
  GetRecommendedCourseFeedbackRequestType,
  GetRecommendedCourseFeedbackResponseType,
  GetRecommendedShortlistFeedbackRequestType,
  GetRecommendedShortlistFeedbackResponseType,
  GetShortlistRequestValidationResponseType,
  GetStudentAssignedCounsellorsResponseType,
  GetStudentOnboardingDetailsResponseType,
  OnboardedStudentGetPreferrencesRequestType,
  OnboardedStudentGetPreferrencesResponseType,
  OnboardedStudentUpdatePreferrencesRequestType,
  OnboardedStudentUpdatePreferrencesResponseType,
  SaveRecommendedCourseFeedbackRequestType,
  SaveRecommendedCourseFeedbackResponseType,
  SaveRecommendedShortlistFeedbackRequestType,
  SaveRecommendedShortlistFeedbackResponseType,
  StudentProfileStatusCheckResponseType,
  UpdateStudentOnboardingDetailsRequestType,
  UpdateStudentOnboardingDetailsResponseType,
} from '@/types/Services';
import { PreferredCitiesResponseType } from '@/types/vas/accomodation';
import { transformResponse } from '@/utils/common';

import { CreateStudentType } from '../validations/createStudent.schema';

export const studentApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCSPStudentData: builder.query<
      definitions['ResponseWrapper«StudentDetailsViewDto»'],
      number
    >({
      query: (studentId) => `/v1/students/${studentId}`,
      providesTags: [`updateCspStudentInfo`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as definitions['ResponseWrapper«StudentDetailsViewDto»'];
        } else {
          return {
            data,
          } as unknown as definitions['ResponseWrapper«StudentDetailsViewDto»'];
        }
      },
    }),
    getCSPStudentDocuments: builder.query<
      GetCspStudentDocumentsresponse,
      number
    >({
      query: (studentId) => `/documents/status/student/${studentId}`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetCspStudentDocumentsresponse;
        } else {
          return { data } as unknown as GetCspStudentDocumentsresponse;
        }
      },
    }),
    createStudent: builder.mutation<BaseResponse<any>, CreateStudentType>({
      query: (payload: any) => ({
        url: `/students`,
        method: `POST`,
        body: payload,
      }),
    }),
    createStudentDetails: builder.mutation<
      BaseResponse<any>,
      { studentId: number; payload: StudentDetailsViewRequest }
    >({
      query: ({ payload, studentId }) => {
        const queryObj: any = {
          url: `/students/${studentId}`,
          body: payload,
          method: `PUT`,
        };
        return queryObj;
      },
    }),

    checkDuplicate: builder.query<
      CheckDuplicateApiResponse,
      { mobileNumber: string; cspId: string }
    >({
      query: ({ mobileNumber, cspId }) =>
        `students/check-duplicate/${encodeURIComponent(
          cspId,
        )}/${encodeURIComponent(mobileNumber)}`,
    }),

    updateStudentInfoById: builder.mutation({
      query: (payload) => ({
        url: `v1/students/${payload[1]}`,
        method: `PUT`,
        body: payload[0],
      }),
    }),

    updateCSpStudentInfo: builder.mutation<
      UpdateCSPStudentInfoResponse,
      { payload: UpdateCSPStudentInfoRequest; studentId: number }
    >({
      query: ({ payload, studentId }) => {
        const queryObj: any = {
          url: `/students/${studentId}`,
          body: payload,
          method: `PUT`,
        };
        return queryObj;
      },
      invalidatesTags: [`updateCspStudentInfo`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateCSPStudentInfoResponse;
        } else {
          return { data } as unknown as UpdateCSPStudentInfoResponse;
        }
      },
    }),
    getAllCSPStudentsData: builder.mutation<
      GetAllCSPStudentResponse,
      GetAllCSPStudentRequest
    >({
      query: (payload) => {
        const queryObj: any = {
          url: `/students/search`,
          body: payload,
          method: `POST`,
        };
        return queryObj;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetAllCSPStudentResponse;
        } else {
          return { data } as unknown as GetAllCSPStudentResponse;
        }
      },
    }),
    getAllCSps: builder.query<GetAllCSPSResponse, void>({
      query: (studentId) => `/v1/csps`,
      providesTags: [`updateCspStudentInfo`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetAllCSPSResponse;
        } else {
          return { data } as unknown as GetAllCSPSResponse;
        }
      },
    }),

    searchStudent: builder.mutation<BaseResponse<any>, any>({
      query: (payload) => ({
        url: `/v1/students/search-recommendation`,
        method: `POST`,
        body: payload,
      }),
      // invalidatesTags: [`Student`],
    }),

    getShortlists: builder.query<
      GetStudentShortlistsResponse,
      { studentId: number }
    >({
      query: ({ studentId }) => `/shortlist/student/${studentId}`,
      providesTags: [`StudentShortLists`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetStudentShortlistsResponse;
        } else {
          return { data } as unknown as GetStudentShortlistsResponse;
        }
      },
    }),

    updateShortlistGroup: builder.mutation<
      UpdateShortListRequestResponse,
      UpdateShortListRequestRequest & { shortListRequestId: number }
    >({
      query: ({
        status,
        studentId,
        shortListRequestId,
        onHoldRemark,
        rejectedReason,
        rejectedReasonRemark,
      }) => {
        return {
          url: `/shortlist/shortlist-request/${shortListRequestId}`,
          method: `PUT`,
          body: {
            status,
            studentId,
            onHoldRemark,
            rejectedReason,
            rejectedReasonRemark,
          },
        };
      },
      invalidatesTags: [`StudentShortLists`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateShortListRequestResponse;
        } else {
          return { data } as unknown as UpdateShortListRequestResponse;
        }
      },
    }),

    updateShortlistRemark: builder.mutation<
      definitions['ResponseWrapper«boolean»'],
      definitions['ShortlistRequestNotesDto'] & { shortListRequestId: number }
    >({
      query: ({ shortListRequestId, ...body }) => ({
        url: `/shortlist/shortlist-request/notes/${shortListRequestId}`,
        method: `POST`,
        body,
      }),
      // invalidatesTags: [`StudentShortLists`],
    }),
    fetchCspCountries: builder.query({
      query: () => ({
        url: `v1/csps/user/countries`,
        method: `GET`,
      }),
    }),
    fetchAllCities: builder.query<
      PreferredCitiesResponseType,
      { country: string; city: string; returnLimit?: number }
    >({
      query: ({ country, city, returnLimit }) => ({
        url: `shared/countries/${country}/cities?city=${city}&returnLimit=${10}`,
        method: `GET`,
      }),
      // providesTags: [`CountryAndState`],
    }),
    // v1/students/{studentId}/assigned-counsellors
    getStudentAssignedCounsellors: builder.query<
      GetStudentAssignedCounsellorsResponseType,
      { studentId: number }
    >({
      query: ({ studentId, ...rest }) => ({
        url: `v1/students/${studentId}/assigned-counsellors`,
        method: `GET`,
      }),
      // providesTags: [`CountryAndState`],
    }),

    //v1/students/{studentId}/course-eligibility-check
    getCourseEligibilityCheck: builder.query<
      CourseEligibilityCheckResponseDto,
      { studentId: number; data: CourseEligibilityCheckRequestDto }
    >({
      query: ({ studentId, data }) => ({
        url: `/v1/students/${studentId}/course-eligibility-check`,
        method: `POST`,
        body: data,
      }),
    }),

    getCourseEligibilityCheckBulk: builder.query<
      CourseEligibilityCheckBulkResponseDto,
      CourseEligibilityCheckBulkRequestDto
    >({
      query: (body) => ({
        url: `/cf/course-eligibility-check-bulk`,
        method: `POST`,
        body: body,
      }),
    }),

    updateStudentOnboardingDetails: builder.mutation<
      UpdateStudentOnboardingDetailsResponseType,
      UpdateStudentOnboardingDetailsRequestType
    >({
      query: ({ data, studentId }) => ({
        url: `/v1/students/${studentId}/details`,
        method: `PUT`,
        body: { ...data },
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateStudentOnboardingDetailsResponseType;
        } else {
          return data as unknown as UpdateStudentOnboardingDetailsResponseType;
        }
      },
      invalidatesTags: [
        `StudentEducationForm`,
        `StudentExamForm`,
        `Student`,
        `GetStudentExamInfo`,
        `GetStudentAcademicInfo`,
      ],
    }),
    getStudentOnboardingDetails: builder.query<
      GetStudentOnboardingDetailsResponseType,
      number
    >({
      query: (studentId) => `/v1/students/${studentId}/details`,
      transformResponse: transformResponse,
      providesTags: [`StudentOnboardingForm`],
      keepUnusedDataFor: 0,
    }),
    getRecommendedShortlistFeedback: builder.query<
      GetRecommendedShortlistFeedbackResponseType,
      GetRecommendedShortlistFeedbackRequestType
    >({
      query: ({ studentId, shortlistRequestId }) =>
        `/shortlist/feedback/${studentId}/${shortlistRequestId}`,
      transformResponse,
      providesTags: [`GetRecommendedShortlistFeedback`],
    }),
    saveRecommendedShortlistFeedback: builder.mutation<
      SaveRecommendedShortlistFeedbackResponseType,
      SaveRecommendedShortlistFeedbackRequestType
    >({
      query: (payload) => {
        const config = {
          url: `/shortlist/feedback/${payload?.studentId}/${payload?.shortlistRequestId}`,
          method: `POST`,
          body: payload?.dto,
        };
        return config;
      },
      transformResponse,
      invalidatesTags: [`GetRecommendedShortlistFeedback`],
    }),
    getRecommendedCourseFeedback: builder.mutation<
      GetRecommendedCourseFeedbackResponseType,
      GetRecommendedCourseFeedbackRequestType
    >({
      query: ({ studentId, shortlistRequestId, courseIds }) => {
        const config = {
          method: `POST`,
          url: `/shortlist/feedback/courses/${studentId}`,
          body: {
            shortlistRequestId,
            courseIds,
          },
        };
        return config;
      },
      transformResponse,
    }),
    saveRecommendedCourseFeedback: builder.mutation<
      SaveRecommendedCourseFeedbackResponseType,
      SaveRecommendedCourseFeedbackRequestType
    >({
      query: (payload) => {
        const config = {
          url: `/shortlist/feedback/${payload?.studentId}/${payload?.shortlistRequestId}/${payload?.courseId}`,
          method: `POST`,
          body: payload?.dto,
        };
        return config;
      },
      transformResponse,
    }),
    getStudentInfoById: builder.query<
      definitions[`ResponseWrapper«StudentDetailsViewDto»`],
      { id: number }
    >({
      query: ({ id }) => `v1/students/${id}`,
      providesTags: [`Student`],
    }),
    validateShortlistRequest: builder.mutation<
      GetShortlistRequestValidationResponseType,
      { studentId: number; body: { preferredCountryGroup: string } }
    >({
      query: ({ studentId, body }) => ({
        url: `/v1/shortlist/shortlist-request/student/${studentId}/validate`,
        method: `POST`,
        body,
      }),
      transformResponse: transformResponse,
      // invalidatesTags: [`ShortlistRequest`],
    }),
    fetchStudentProfileStatus: builder.query<
      StudentProfileStatusCheckResponseType,
      number
    >({
      query: (studentId) => ({
        url: `v1/students/${studentId}/profile-status`,
      }),
      transformResponse: transformResponse,
    }),
    createShortlistRequest: builder.mutation<
      CreateShortlistRequestResponseType,
      { studentId: number; body: definitions[`StudentPreferencesDto`] }
    >({
      query: ({ studentId, body }) => ({
        url: `/v1/shortlist/shortlist-request/student/${studentId}`,
        method: `POST`,
        body,
      }),
      transformResponse: transformResponse,
      invalidatesTags: [`StudentShortLists`, `Shortlist`, `Student`],
    }),
    getOnboardedStudentPreferences: builder.query<
      OnboardedStudentGetPreferrencesResponseType,
      OnboardedStudentGetPreferrencesRequestType
    >({
      query: ({ studentId }) =>
        `v1/students/onboarding-form-details/${studentId}`,
      providesTags: [`StudentOnboardingFormPreference`],
      transformResponse: transformResponse,
    }),
    updateOnboardedStudentPreferences: builder.mutation<
      OnboardedStudentUpdatePreferrencesResponseType,
      OnboardedStudentUpdatePreferrencesRequestType
    >({
      query: ({ studentId, ...body }) => ({
        url: `v1/students/onboarding-form-details/${studentId}`,
        method: `PUT`,
        body,
      }),
      invalidatesTags: [`StudentOnboardingFormPreference`],
      transformResponse: transformResponse,
    }),
  }),
});

export const {
  useGetCSPStudentDataQuery,
  useGetCSPStudentDocumentsQuery,
  useUpdateCSpStudentInfoMutation,
  useGetAllCSPStudentsDataMutation,
  useGetAllCSpsQuery,
  useSearchStudentMutation,
  useGetShortlistsQuery,
  useUpdateShortlistGroupMutation,
  useFetchCspCountriesQuery,
  useCreateStudentMutation,
  useCreateStudentDetailsMutation,
  useLazyCheckDuplicateQuery,
  useUpdateStudentInfoByIdMutation,
  useUpdateShortlistRemarkMutation,
  useLazyGetCSPStudentDataQuery,
  useLazyFetchAllCitiesQuery,
  useLazyGetStudentAssignedCounsellorsQuery,
  useLazyGetCourseEligibilityCheckQuery,
  useUpdateStudentOnboardingDetailsMutation,
  useLazyGetCourseEligibilityCheckBulkQuery,
  useLazyGetRecommendedShortlistFeedbackQuery,
  useGetRecommendedCourseFeedbackMutation,
  useSaveRecommendedShortlistFeedbackMutation,
  useSaveRecommendedCourseFeedbackMutation,
  useGetRecommendedShortlistFeedbackQuery,
  useLazyGetStudentInfoByIdQuery,
  useLazyGetShortlistsQuery,
  useLazyGetStudentOnboardingDetailsQuery,
  useValidateShortlistRequestMutation,
  useGetStudentOnboardingDetailsQuery,
  useFetchStudentProfileStatusQuery,
  useCreateShortlistRequestMutation,
  useUpdateOnboardedStudentPreferencesMutation,
  useGetOnboardedStudentPreferencesQuery,
} = studentApi;
