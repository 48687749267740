/* eslint-disable @typescript-eslint/ban-types */
import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { selectUserDetails } from 'app/features/user/userSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { documentTypeOptions, OptionType } from 'lib/constant';
import { useRouter } from 'next/router';
import { Roles } from 'types/global';

import { initializeAttachedDocuments } from '@/features/miscellaneousDocSlice';
import useStateWithCallback from '@/hooks/useStateWithCallback';
import { useLazyGetCSPStudentDataQuery } from '@/services/student';
import {
  applicationStatusRank,
  roleStatus,
} from 'components/commons/ApplicationOptionsMapper';
import { applicationStatus } from 'components/commons/ApplicationOptionsMapper';

import { DeferralFormDataType } from '../../Deferral/DeferralProvider';
import {
  eachStudentApplicationData,
  fetchDocumentKeys,
  fetchOpsManagerList,
  fetchUploadDocument,
  getRemarks,
} from '../ApplicationApis';
import { multiDocsTypes } from '../CommonFunctions';
// import { fetchProfileData } from '../ProfileSection/ProfileSectionApis';
import {
  fetchGeebeeVisaCounsellors,
  fetchVisaRemarks,
} from '../VisaSection/VisaSectionApis';

import { Document, Documents, FormData, ProfileDataType } from './interface';

const initalFormData = {
  applicationDate: ``,
  annualTuitionFees: ``,
  applicationDropDate: ``,
  applicationFilerRemark: ``,
  applicationLink: ``,
  applicationSentDate: ``,
  applicationStageReason: ``,
  applicationType: ``,
  availablePriorities: [],
  country: ``,
  courseStartDate: ``,
  documentVerificationDate: ``,
  dueDate: ``,
  dueDateOffer: ``,
  feesPaid: ``,
  feesWith: ``,
  filingOwnerName: ``,
  followUpDate: ``,
  id: null,
  intake: ``,
  pathwayProvider: ``,
  cmsUniversityNames: ``,
  cmsUniversityName: ``,
  cmsUniversityId: ``,
  lastDateFeePayment: ``,
  lastDateOfferAcceptance: ``,
  onHoldMissingDocuments: null,
  onHoldIncorrectDocuments: null,
  onHoldUniversityMissingDocuments: null,
  onHoldUniversityIncorrectDocuments: null,
  incorrectDocuments: null,
  number: null,
  password: ``,
  paymentProofReceivedDate: ``,
  paymentRemarks: ``,
  paymentStatus: ``,
  paymentType: ``,
  programName: ``,
  priority: null,
  isPriorityChangeAllowed: null,
  reasonForDrop: ``,
  reasonForHold: ``,
  reasonForHoldExplanation: ``,
  reasonForHoldUniversity: ``,
  reasonForHoldUniversityExplanation: ``,
  reasonForRejection: ``,
  scholarApplicationId: null,
  stage: ``,
  studentReferenceId: null,
  universityName: ``,
  universityReferenceId: ``,
  username: ``,
  remarks: [],
  cspName: null,
  cspId: null,
  student: {
    contactNumber: ``,
    contactNumberWithCountryCode: ``,
    countryCode: ``,
    dob: ``,
    email: ``,
    firstName: ``,
    id: null,
    lastName: ``,
    scholarStudentId: null,
  },
  studentExam: {
    examType: ``,
    id: null,
    listeningScore: null,
    readingScore: null,
    scholarApplicationId: null,
    speakingScore: null,
    totalScore: null,
    writingScore: null,
  },
  tuitionFeeCurrency: null,
  opsManagerId: null,
  visaDetails: {
    country: null,
    gbVisaCounsellor: null,
    gbVisaCounsellorId: null,
    id: null,
    interviewDate: null,
    leapVisaCounsellor: null,
    scholarStudentId: null,
    scholarVisaId: null,
    universityName: null,
    visaApplicationStatus: null,
    visaApplicationSubStatus: null,
    visaDocumentStatus: null,
    studentId: null,
    applicationId: null,
    isArchived: false,
  },
  visaDetailsOtherApp: {
    country: null,
    gbVisaCounsellor: null,
    gbVisaCounsellorId: null,
    id: null,
    interviewDate: null,
    leapVisaCounsellor: null,
    scholarStudentId: null,
    scholarVisaId: null,
    universityName: null,
    visaApplicationStatus: null,
    visaApplicationSubStatus: null,
    visaDocumentStatus: null,
    studentId: null,
    applicationId: null,
    isArchived: false,
  },
  visaDocumentStatus: null,
  visaDocumentTypes: null,
  visaDocOnholdReasonExplanation: null,
  visaDocReviewStatus: null,
  conditionalReasonCleared: null,
  conditionalAdmitReasonList: null,
  counsellorName: null,
  conditionStatus: null,
  conditionalReason: null,
  admitDate: ``,
  conditionFollowUpDate: ``,
  conditionMetDate: ``,
  nextValidStates: [],
  deferralRequest: {} as DeferralFormDataType,
  canDeferralBeRaised: false,
  paymentVerificationStatus: null,
  reasonForOnHoldResolvedPaymentVerification: null,
  fullOrPartialPayment: null,
  nextValidPaymentVerificationStates: [],
  paymentVerificationStatusBar: [],
  followUpDateForCASORI20: null,
  reasonForOnHoldPaymentVerification: null,
  opsManagerName: ``,
  cspSecondaryEmails: ``,
  qcTeamUserName: ``,
  isOnshore: false,
  qcTeamUserId: null,
  documentVerifiedSubStatus: null,
  universityCommunicationPendingSubStatus: null,
  documentVerifiedSubStatusOpenDate: null,
  documentVerifiedSubStatusRemarks: null,
  universityCommunicationPendingSubStatusRemarks: null,
  assignedToId: null,
  assignedToName: ``,
  searchByKey: null,
  counsellorEmail: null,
  paymentDetails: null,
  eligibleDocumentKeysForOnHold: null,
  degreeId: null,
  degreeName: null,
  courseId: null,
  courseLink: null,
  counsellorNameContactNumber: null,
};

interface ApplicationProviderProps {
  children: React.ReactNode;
}

interface CustomRemarks {
  applicationId?: number;
  date?: string;
  givenBy?: string | null;
  id?: number | null;
  remark?: string;
}
export interface ConditionsReasonsType {
  condition: string;
  conditionDescription: string | null;
  conditionId: number;
  studentId: number;
  applicationId: number;
  conditionStatus: string | null;
  rejectReason: string | null;
  identifier: number;
}
interface ApplicationContextInterface {
  isDownloadAllEnabled: boolean;
  documents: Documents;
  formData: FormData;
  fetchStudentData: Function;
  statusOptions: Array<OptionType>;
  role: string;
  setRole: Function;
  documentOptions: Array<OptionType>;
  documentsList: Array<Document>;
  checkDisableStatus: Function;
  getProfileData: Function;
  fetchDocuments: Function;
  profileData: {};
  appId: number | null;
  remarksData: [];
  setFormData: Function;
  fetchStudentDataFailed: boolean;
  documentKeys: { label: string; value: string }[];
  fetchDocumentKeysList: Function;
  opsManagers: { label: string; value: number | string | null }[];
  handleFetchOpsManagers: Function;
  gbCounsellorsData: OptionType[];
  visaRemarksData: CustomRemarks[] | [];
  fetchGeebeeVisaCounsellorsData: Function;
  fetchVisaRemarksData: Function;
  conditionalAdmitReasonsData: ConditionsReasonsType[] | [];
  documentAttachedWithInstallment: any[];
  setDocumentAttachedWithInstallment: Function;
}

export const ApplicationContext = createContext<ApplicationContextInterface>({
  isDownloadAllEnabled: true,
  documents: {},
  formData: initalFormData,
  fetchStudentData: (id: number) => {},
  statusOptions: [],
  role: ``,
  setRole: () => {},
  documentOptions: [],
  documentsList: [],
  checkDisableStatus: () => {},
  appId: null,
  remarksData: [],
  setFormData: () => {},
  fetchStudentDataFailed: false,
  getProfileData: () => {},
  profileData: {},
  fetchDocuments: (
    studentId: number | null,
    applicationId: number | null,
  ) => {},
  documentKeys: [],
  fetchDocumentKeysList: () => {},
  handleFetchOpsManagers: () => {},

  opsManagers: [],
  visaRemarksData: [],
  gbCounsellorsData: [],
  fetchVisaRemarksData: () => {},
  fetchGeebeeVisaCounsellorsData: () => {},
  conditionalAdmitReasonsData: [],
  documentAttachedWithInstallment: [],
  setDocumentAttachedWithInstallment: () => {},
});
const initialProfileData: ProfileDataType = {
  academicInfo: [
    {
      university: null,
      course: null,
      grade: null,
      startDate: null,
      endDate: null,
      backLogs: null,
      courseType: ``,
      gradeTotal: null,
      courseLanguage: null,
      city: null,
      country: null,
    },
  ],
  personalProfile: {
    applicationsCount: null,
    backgroundInformation: [
      {
        questionId: null,
        question: null,
        response: null,
      },
    ],
    id: null,
    editProfile: {
      contactNumber: null,
      contactNumberWithCountryCode: null,
      countryCode: null,
      dob: null,
      email: null,
      firstName: null,
      gender: null,
      lastName: null,
      maritalStatus: null,
      middleName: null,
    },
    personalInformation: {
      dob: null,
      emergencyContactName: null,
      emergencyContactNumber: null,
      emergencyRelationship: null,
      gender: null,
      maritalStatus: null,
    },
    mailingAddress: {
      addressLine1: null,
      addressLine2: null,
      street: null,
      city: null,
      country: null,
      state: null,
      pinCode: null,
    },
    permanentAddress: {
      addressLine1: null,
      addressLine2: null,
      street: null,
      city: null,
      country: null,
      state: null,
      pinCode: null,
    },
    passportInfo: {
      issueDate: null,
      passportNumber: null,
      expiryDate: null,
      issueCountry: null,
      cityOfBirth: null,
      nationality: null,
    },
  },
  studentExamInfo: [
    {
      totalScore: null,
      readingScore: null,
      writingScore: null,
      examType: ``,
      listeningScore: null,
      speakingScore: null,
      literacy: null,
      conversation: null,
      comprehension: null,
      production: null,
      id: null,
      gmatVerbalScore: null,
      greVerbalScore: null,
      gmatQuantitativeScore: null,
      greQuantScore: null,
      gmatWritingScore: null,
      greWritingTest: null,
      gmatIntegratedReasoning: null,
      gmatExamDate: null,
      greExamDate: null,
      examDate: null,
    },
  ],
  workExperience: [
    {
      companyName: ``,
      employmentType: null,
      designation: null,
      startDate: null,
      endDate: null,
      isWorkingPresently: null,
      id: null,
    },
  ],
};

const allowedRolesForChanges: string[] = [
  roleStatus.GB_ZONAL_MANAGER,
  roleStatus.GB_REPRESENTATIVE,
  roleStatus.GB_COUNTRY_HEAD,
  roleStatus.GB_COUNSELLOR,
  roleStatus.GB_OPS_MANAGER,
  roleStatus.GB_ADMIN,
  roleStatus.GB_HEAD_CUSTOMER_CARE,
  roleStatus.GB_VISA_TEAM,
  Roles.qc,
  Roles.gbInvoicing,
];

const ApplicationProvider: FunctionComponent<ApplicationProviderProps> = (
  props,
) => {
  const router = useRouter();
  const { children } = props;
  const dispatch = useAppDispatch();
  const [documents, setDocuments] = useState<Documents>({});
  const [documentAttachedWithInstallment, setDocumentAttachedWithInstallment] =
    useState<any[]>([]);
  const [isDownloadAllEnabled, setIsDownloadAllEnabled] = useState(true);
  const [formData, setFormData] =
    useStateWithCallback<FormData>(initalFormData);
  const [statusOptions, setStatusOptions] = useState<Array<OptionType>>([]);
  const [role, setRole] = useState<string>(``);
  const [documentOptions, setDocumentOptions] = useState<Array<OptionType>>([]);
  const [documentsList, setDocumentsList] = useState<Array<Document>>([]);
  const [appId, setAppId] = useState<number | null>(null);
  const [remarksData, setRemarksData] = useState<[]>([]);
  const [profileData, setProfileData] =
    useState<ProfileDataType>(initialProfileData);
  const [documentKeys, setDocumentKeys] = useState<
    { label: string; value: string }[]
  >([]);
  const [gbCounsellorsData, setGbCounsellorsData] = useState<OptionType[]>([]);
  const [visaRemarksData, setVisaRemarksData] = useState<CustomRemarks[] | []>(
    [],
  );
  const [conditionalAdmitReasonsData, setConditionalAdmitReasonsData] =
    useState<ConditionsReasonsType[]>([]);

  const [fetchStudentDataFailed, setFetchStudentDataFailed] =
    useState<boolean>(false);
  const [opsManagers, setOpsManagers] = useState<
    { label: string; value: number | string | null }[]
  >([]);
  const userDetails = useAppSelector((state) => selectUserDetails(state));
  useEffect(() => {
    if (Object.keys(documents).length) updateDocsOptionsAndDocuments();
  }, [documents]);
  const fetchVisaRemarksData = async () => {
    const visaId = formData?.visaDetails?.id;
    if (visaId) {
      const res = await fetchVisaRemarks(visaId);

      if (res?.success) {
        setVisaRemarksData(res?.data);
      } else {
        toast.error(`Something went wrong.Please contact the tech team`);
      }
    }
  };
  const fetchGeebeeVisaCounsellorsData = async () => {
    const res = await fetchGeebeeVisaCounsellors(Number(router.query?.slotId));
    if (res?.success) {
      const data: Array<{ label: string; value: number }> = res?.data
        ? res?.data?.map(
            (elem: { id: number; firstName: string; lastName: string }) => {
              return {
                label: `${elem?.firstName ?? ``} ${elem?.lastName ?? ``}`,
                value: elem?.id,
              };
            },
          )
        : [];
      setGbCounsellorsData(data);
    } else {
      toast.error(`Something went wrong.Please contact the tech team`);
    }
  };

  const [fetchProfileData, { data: studentData }] =
    useLazyGetCSPStudentDataQuery();

  useEffect(() => {
    setProfileData(studentData?.data as any);
    setFormData({
      ...formData,
      student: {
        ...formData?.student,
        ...studentData?.data?.personalProfile?.editProfile,
      },
    });
  }, [studentData?.data]);
  useEffect(() => {
    if (formData?.visaDetails?.id) {
      fetchVisaRemarksData();
      fetchGeebeeVisaCounsellorsData();
    }
  }, [formData?.visaDetails]);
  const fetchStudentData = async (id: number, successCallback?: () => void) => {
    try {
      const res = await eachStudentApplicationData(id);

      if (!res.data.success)
        throw new Error(
          res.data.message ||
            `Something went wrong.Please contact the tech team`,
        );
      const data: FormData = res.data.data;
      dispatch(
        initializeAttachedDocuments(
          data.paymentDetails?.transactions?.map((item) => {
            return {
              transactionId: item.id,
              document: item.paymentProofDocument,
            };
          }) || [],
        ),
      );
      const remarkRes = await getRemarks(data?.id);
      if (!remarkRes.success)
        throw new Error(
          remarkRes.message ||
            `Something went wrong.Please contact the tech team`,
        );
      setRemarksData(remarkRes.data);
      setAppId(data?.id);
      const {
        id: applicationId,
        stage,
        scholarApplicationId,
        student: { scholarStudentId, id: studentId },
      } = data;

      const conditionalInfo: ConditionsReasonsType[] = [];
      data?.conditionalAdmitReasonList?.map((item: any, index: number) =>
        conditionalInfo.push({
          condition: item.condition,
          conditionDescription: item.conditionDescription,
          conditionId: item.conditionId,
          studentId: item.studentId,
          applicationId: item.applicationId,
          conditionStatus: item.conditionStatus,
          rejectReason: item.rejectReason,
          identifier: index,
        }),
      );
      const payload: string[] = [];
      conditionalInfo.forEach((item: any) => payload.push(item.condition));

      setConditionalAdmitReasonsData(conditionalInfo);
      fetchDocuments(studentId, applicationId);
      getStateValues(stage, data.nextValidStates);
      if (payload.length > 0) {
        data.conditionalReason = payload;
      }
      setFormData(data, () => {
        if (successCallback) {
          successCallback();
        }
      });
      return data;
    } catch (error: any) {
      setFetchStudentDataFailed(true);
      toast.error(error.message);
    }
  };

  const fetchDocuments = async (
    studentId: number | null,
    applicationId: number | null,
  ) => {
    try {
      const res = await fetchUploadDocument({
        studentId: studentId,
        applicationId: applicationId,
      });
      if (!res.success) throw Error(``);
      setDocuments(res.data.documentStatus);
      setIsDownloadAllEnabled(res.data.isDownloadAllEnabled);
    } catch (error) {
      toast.error(`Something went wrong.Please contact the tech team`);
    }
  };
  let options: any;
  const getStateValues = (
    stage: string,
    nextValidStates: Array<{ label: string; labelKey: string }> = [],
  ) => {
    const stageLabel = stage
      .split(`_`)
      .map((word: string) => {
        return `${word.substring(0, 1)}${word
          .substring(1)
          .toLocaleLowerCase()}`;
      })
      .join(` `);
    options = nextValidStates?.map((elem) => ({
      label: elem?.label,
      value: elem.labelKey,
    }));
    setStatusOptions([{ label: stageLabel, value: stage }, ...options]);
  };
  const getProfileData = async () => {
    try {
      const studentId = formData?.student?.id;
      if (studentId) {
        const res = await fetchProfileData(studentId);
        setProfileData(res?.data?.data as any);
      }
    } catch {
      toast.error(`Something went wrong.Please contact tech team`);
    }
  };
  const fetchDocumentKeysList = async () => {
    try {
      const res = await fetchDocumentKeys();
      if (res && res?.length > 0) {
        setDocumentKeys(res);
      }
    } catch {
      toast.error(`Something went wrong.Please contact tech team`);
    }
  };
  const opslist = [] as any;
  const handleFetchOpsManagers = async () => {
    if (userDetails && userDetails.userType === Roles.qc) return;
    try {
      const payload = {
        applicationId: formData?.id,
      };
      const res = await fetchOpsManagerList(payload);
      res?.map((item: any) => {
        opslist?.push({
          label: `${item?.firstName}${` `}${item?.lastName}`,
          value: item?.id,
        });
      });
      setOpsManagers(opslist);
    } catch (err: any) {
      toast.error(err.message);
    }
  };
  const updateDocsOptionsAndDocuments = () => {
    let dataKeys = Object.keys(documents);
    dataKeys = dataKeys?.filter((elem: any) => !multiDocsTypes.includes(elem));
    const newMiscOptions = documentTypeOptions?.filter(
      (item: any) => !dataKeys.includes(item.value),
    );
    setDocumentOptions(newMiscOptions);
    //removing the ulimited keys condition as a hot fix
    const docs = Object.keys(documents)?.map((key: any) => documents[key]);
    if (docs?.length > 0) {
      const singleArrDocs = docs?.reduce((ele1: any, ele2: any) =>
        ele1.concat(ele2),
      );
      setDocumentsList(singleArrDocs);
    } else {
      setDocumentsList([]);
    }
  };

  const checkDisableStatus = useCallback(() => {
    if (
      formData.qcDetails?.qcStatus === `PENDING` &&
      formData.stage === applicationStatus.APPLICATION_RECEIVED
    ) {
      return true;
    } else if (allowedRolesForChanges.includes(role)) {
      return false;
    } else if (role === roleStatus.GB_VIEWER) {
      return true;
    } else {
      return true;
    }
  }, [role, formData.stage, formData.qcDetails?.qcStatus]);

  return (
    <ApplicationContext.Provider
      value={{
        isDownloadAllEnabled,
        documents,
        formData,
        fetchStudentData,
        statusOptions,
        role,
        setRole,
        documentOptions,
        documentsList,
        checkDisableStatus,
        fetchDocuments,
        appId,
        remarksData,
        setFormData,
        fetchStudentDataFailed,
        getProfileData,
        profileData,
        documentKeys,
        fetchDocumentKeysList,
        opsManagers,
        gbCounsellorsData,
        visaRemarksData,
        handleFetchOpsManagers,
        fetchVisaRemarksData,
        fetchGeebeeVisaCounsellorsData,
        conditionalAdmitReasonsData,
        documentAttachedWithInstallment,
        setDocumentAttachedWithInstallment,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationProvider;
